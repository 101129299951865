const JWT = (function() {
  let JWT_TOKEN = '';

  function token(__token) {
    if (!!__token) {
      JWT_TOKEN = __token;
      if (localStorage) {
        localStorage.setItem('token', __token);
      }
    } else {
      if (localStorage && !JWT_TOKEN) {
        JWT_TOKEN = localStorage.getItem('token') || '';
      }
      return JWT_TOKEN;
    }
  }

  function clear() {
    JWT_TOKEN = '';
    if (localStorage) {
      localStorage.removeItem('token');
    }
  }

  function data() {}

  return { token, clear, data };
})();
export default JWT;
