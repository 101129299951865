import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import Avatar from '../../../components/avatar';

import './auth-welcome.scss';

function AuthWelcome({ name }) {
  const intl = useIntl();

  return (
    <Fragment>
      <div className="auth-welcome__avatar">
        <Avatar size={110} />
      </div>
      <div className="auth-welcome__text">{intl.formatMessage({ id: 'authWelcome.welcomeText' })}</div>
      <div className="auth-welcome__name">{name}</div>
    </Fragment>
  );
}

AuthWelcome.defaultProps = {
  name: '_',
};

AuthWelcome.propTypes = {
  name: PropTypes.string,
};

export default AuthWelcome;
