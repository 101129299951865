import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

import Button from '../../../components/button';
import { ru } from '../../../constants/phone_localization';

import './auth-phone.scss';

const SUPPORTED_COUNTRIES = ['us', 'ru', 'fr', 'ch', 'gb', 'se', 'cy', 'mc'];
const DEFAULT_COUNTRY = 'ru';

function AuthPhone({ authByPhone }) {
  const intl = useIntl();

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [sendData, setSendData] = useState(false);

  const _authByPhone = async () => {
    if (sendData) {
      return;
    }
    setSendData(true);
    await authByPhone(phone, function(error = '') {
      if (error) {
        setError(error);
      }
      setSendData(false);
    });
  };

  const onChange = value => {
    setPhone(value);
    setError('');
  };

  const onKeyDown = event => {
    if (event.which === 13) {
      event.preventDefault();
      _authByPhone();
    }
  };

  return (
    <Fragment>
      <div className="auth-phone__persacc">{intl.formatMessage({ id: 'authPhone.login' })}</div>
      <div className="auth-phone__phonerequest">{intl.formatMessage({ id: 'authPhone.enterPhone' })}</div>
      <div className={'auth-phone__number'}>
        <PhoneInput
          specialLabel=""
          onlyCountries={SUPPORTED_COUNTRIES}
          country={DEFAULT_COUNTRY}
          disableAreaCodes={true}
          countryCodeEditable={false}
          localization={ru}
          preserveOrder={['onlyCountries']}
          masks={{ mc: '..-..-..-..', se: '..-...-..-..' }}
          inputClass="auth-phone__number_input"
          inputProps={{
            name: 'number_input',
            autoFocus: true,
          }}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {error && <div className="auth-phone__error">{error}</div>}
      </div>
      <div className="auth-phone__entry">
        <Button onClick={_authByPhone} color="blue" className="auth-phone__button" loading={sendData}>
          {intl.formatMessage({ id: 'authPhone.logIn' })}
        </Button>
      </div>
    </Fragment>
  );
}

AuthPhone.propTypes = {
  authByPhone: PropTypes.func.isRequired,
};
export default AuthPhone;
