import React from 'react';
import PropTypes from 'prop-types';

import { useIntl } from 'react-intl';

import loader from '../../images/loader-white.png';

import './button.scss';

function Button({ size, color, children, onClick, className, loading }) {
  const intl = useIntl();

  const classNames = ['button', `--${size}`, `--${color}`, className];
  const classNamesContent = ['button__content'];
  if (loading) {
    classNamesContent.push('--hidden');
  }
  const onClickHandler = () => {
    if (!loading) {
      onClick();
    }
  };
  return (
    <div className={classNames.join(' ')} onClick={onClickHandler}>
      {loading && <img src={loader} alt={intl.formatMessage({ id: 'button.loading' })} className="button__loading" />}
      <div className={classNamesContent.join(' ')}>{children}</div>
    </div>
  );
}

Button.defaultProps = {
  size: 'normal',
  color: 'blue',
  className: '',
  loading: false,
};

Button.propTypes = {
  size: PropTypes.oneOf(['small', 'normal', 'big']),
  color: PropTypes.oneOf(['red', 'blue', 'black', 'grey', 'link']),
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default Button;
