import React, { Fragment, useEffect } from 'react';
import './dots.scss';
import PropTypes from 'prop-types';
import Input from '../../../components/input';

const id = 'authPin';
function Dots({ pin, onChangePin }) {
  useEffect(() => {
    const anyClickListener = () => {
      document.getElementById(id).focus();
    };
    document.body.addEventListener('click', anyClickListener);
    return function cleanup() {
      document.body.removeEventListener('click', anyClickListener);
    };
  }, []);
  const numbers = Array.from({ length: 4 }, (v, i) => i).map(i => typeof pin[i] === 'string');
  return (
    <Fragment>
      <label htmlFor={id}>
        <div className="auth-pin-dots">
          {numbers.map((state, index) => (
            <div key={index} className={'auth-pin-dot' + (state ? ' --input' : '')} />
          ))}
        </div>
      </label>
      <div className="auth-pin-input">
        <Input id={id} isNumeric={true} autoFocus={true} value={pin} onChange={onChangePin} maxLength={4} />
      </div>
    </Fragment>
  );
}

Dots.propTypes = {
  pin: PropTypes.string.isRequired,
  onChangePin: PropTypes.func.isRequired,
};
export default Dots;
