import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useIntl, FormattedHTMLMessage } from 'react-intl';

import Input from '../../../components/input';
import * as phoneCodes from '../../../constants/phone_codes';
import phoneMasks from '../../../constants/phone_masks';
import { formatWithMask } from '../../../utils/mask';

import './auth-sms.scss';

const MAX_LENGTH = 4;

const ATTEMPT_COUNT = 3;
function AuthSms({ authBySms, clearAuth, phone }) {
  const intl = useIntl();

  const attemptMessage = intl.formatMessage({ id: 'authSms.attempNumberExhausted' });

  const [accessAttempt, setAccessAttempt] = useState(0);
  const [sms, setSms] = useState('');
  const [error, setError] = useState('');
  const [noSms, setNoSms] = useState(false);
  const [viewPhone, setViewPhone] = useState('0');
  const onChange = sms => {
    setSms(sms);
    setError('');
    if (sms.length === 4) {
      const noAttempts = accessAttempt + 1 === ATTEMPT_COUNT;
      setAccessAttempt(accessAttempt + 1);
      authBySms(sms, function(error = '') {
        if (error) {
          setError((noAttempts && attemptMessage) || error);
          if (noAttempts) {
            setNoSms(true);
          }
        }
      });
    }
  };
  useEffect(() => {
    for (const code in phoneCodes) {
      if (phone.indexOf(phoneCodes[code]) === 0) {
        setViewPhone(
          `+${phoneCodes[code]} ${formatWithMask(phone.slice(phoneCodes[code].length), phoneMasks[phoneCodes[code]])}`
        );
      }
    }
  }, [phone]);
  return (
    <Fragment>
      <div className="auth-sms__header">{intl.formatMessage({ id: 'authSms.confirmation' })}</div>
      <div className="auth-sms__text">
        <FormattedHTMLMessage
          id="authSms.enterPin"
          values={{
            br: () => <br />,
            number: viewPhone,
          }}
        />
      </div>
      <div className="auth-sms__sms">
        <Input
          value={sms}
          onChange={onChange}
          maxLength={MAX_LENGTH}
          className="auth-sms__input"
          autoFocus={true}
          isNumeric={true}
          readOnly={accessAttempt === ATTEMPT_COUNT}
        />
        {error && <div className="auth-sms__error">{error}</div>}
        {noSms && (
          <div className="auth-sms__none" onClick={clearAuth}>
            {intl.formatMessage({ id: 'authSms.getNewSms' })}
          </div>
        )}
      </div>
    </Fragment>
  );
}

AuthSms.propTypes = {
  authBySms: PropTypes.func.isRequired,
  clearAuth: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
};
export default AuthSms;
