import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './input.scss';
import { formatWithMask } from '../../utils/mask';
import { HIDDEN, PASSWORD, TEXT } from './input_types';
import uniqueId from '../../utils/unique_id';

function Input({
  onChange,
  value,
  placeholder,
  type,
  className,
  maxLength,
  onEnter,
  autoFocus,
  counter,
  isNumeric,
  mask,
  id = 'input' + uniqueId(),
  autoComplete,
  floatingLabel,
  readOnly,
  error,
}) {
  const inputRef = useRef(null);
  useLayoutEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      });
    }
  }, [inputRef, autoFocus]);

  const _className = [floatingLabel ? 'input-fl' : 'input', className];
  if (counter) {
    _className.push('--counter');
  }
  if (error) {
    _className.push('--error');
  }

  const _onKeyDown = e => {
    if (e.which === 13) {
      onEnter();
    }
  };
  const _onChange = e => {
    let _value = e.target.value;
    if (isNumeric) {
      _value = _value.replace(/\D/g, '');
    }
    if (mask) {
      _value = _value.slice(0, mask.match(/#/g).length);
    }
    if (_value !== value) {
      onChange(_value);
    }
  };
  let finalValue = formatWithMask(value, mask);

  return floatingLabel ? (
    <label htmlFor={id} className={_className.join(' ').trim()}>
      <input
        ref={inputRef}
        type={type}
        value={finalValue}
        onChange={_onChange}
        maxLength={maxLength}
        onKeyDown={_onKeyDown}
        id={id}
        autoComplete={autoComplete}
        placeholder="&nbsp;"
      />
      <span className="label">{placeholder}</span>
      <span className="border" />
      {counter && (
        <span className="counter">
          {value.length}
          {maxLength !== -1 ? `/${maxLength}` : ''}
        </span>
      )}
    </label>
  ) : (
    <div className={_className.join(' ').trim()}>
      <input
        ref={inputRef}
        type={type}
        value={finalValue}
        onChange={_onChange}
        placeholder={placeholder}
        maxLength={maxLength}
        onKeyDown={_onKeyDown}
        id={id}
        autoComplete={autoComplete}
        readOnly={readOnly}
      />
      {counter && (
        <div className="input__counter">
          {value.length}
          {maxLength !== -1 ? `/${maxLength}` : ''}
        </div>
      )}
      <div className="input__line" />
    </div>
  );
}

Input.defaultProps = {
  type: TEXT,
  value: '',
  placeholder: '',
  className: '',
  onChange: _ => _,
  onEnter: _ => _,
  maxLength: -1,
  autoFocus: false,
  counter: false,
  isNumeric: false,
  mask: '',
  autoComplete: 'off',
  floatingLabel: false,
  readOnly: false,
  error: '',
};
Input.propTypes = {
  type: PropTypes.oneOf([TEXT, HIDDEN, PASSWORD]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.bool,
  counter: PropTypes.bool,
  isNumeric: PropTypes.bool,
  mask: PropTypes.string,
  id: PropTypes.string,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  floatingLabel: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
};

export default Input;
