export const formatWithMask = (originalValue = '', mask = '') => {
  let finalValue = '';
  if (mask) {
    let specCounter = 0;
    mask.split('').forEach((sym, index) => {
      if (sym !== '#' && originalValue[index - specCounter]) {
        finalValue += sym;
        specCounter++;
      } else {
        finalValue += originalValue[index - specCounter] || '';
      }
    });
  } else {
    finalValue = originalValue;
  }
  return finalValue;
};
